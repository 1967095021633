<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.2" cx="7" cy="7" r="7" fill="#18181B" />
    <path d="M3.5 7L6.5 9.7L10.5 4" stroke="#18181b" />
  </svg>
</template>

<script>
export default {
  name: "IconCheck",
};
</script>

<style scoped lang="scss"></style>
