var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "44",
        height: "59",
        viewBox: "0 0 44 59",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M36.9442 37.7817C36.9442 29.4973 30.2287 22.7805 21.9442 22.7805C13.6585 22.7805 6.94421 29.4973 6.94421 37.7817C6.94421 46.0662 13.6585 52.7817 21.9442 52.7817C30.2287 52.7817 36.9442 46.0662 36.9442 37.7817",
          stroke: "#E14313",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M22.2344 24.0575L25.2373 36.3313C25.76 38.4676 24.1423 40.5288 21.943 40.5288C19.7436 40.5288 18.126 38.4676 18.6486 36.3313L21.6516 24.0575C21.7262 23.7526 22.1598 23.7526 22.2344 24.0575ZM22.6496 38.0366C23.0401 37.6461 23.0401 37.0129 22.6496 36.6224C22.259 36.2318 21.6259 36.2318 21.2354 36.6224C20.8448 37.0129 20.8448 37.6461 21.2354 38.0366C21.6259 38.4271 22.259 38.4271 22.6496 38.0366Z",
          fill: "#E14313",
        },
      }),
      _c("path", {
        attrs: {
          d: "M15.9464 6.8406C15.9104 5.1486 16.5044 3.6966 17.7284 2.4846C18.9644 1.2726 20.4284 0.678603 22.1204 0.702603C23.1644 0.702603 24.1184 0.930604 24.9824 1.3866C25.8584 1.8426 26.5484 2.4666 27.0524 3.2586L26.0084 4.0866C25.0364 2.6946 23.7464 1.9986 22.1384 1.9986C20.7944 1.9986 19.6664 2.4606 18.7544 3.3846C17.8544 4.3086 17.4044 5.4606 17.4044 6.8406C17.4044 8.2206 17.8544 9.3726 18.7544 10.2966C19.6664 11.2206 20.7944 11.6826 22.1384 11.6826C23.7464 11.6826 25.0364 10.9866 26.0084 9.5946L27.0524 10.4226C26.5484 11.2146 25.8584 11.8386 24.9824 12.2946C24.1184 12.7506 23.1644 12.9786 22.1204 12.9786C20.4284 13.0026 18.9644 12.4086 17.7284 11.1966C16.5044 9.9846 15.9104 8.5326 15.9464 6.8406Z",
          fill: "#18181B",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }