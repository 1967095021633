var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "card",
        _vm.shadow ? "card--shadow" : "",
        _vm.flat ? "card--flat" : "",
        _vm.animateHover ? "card--animate-hover" : "",
      ],
      on: { click: _vm.click },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }