<template>
  <div
    :class="[
      'card',
      shadow ? 'card--shadow' : '',
      flat ? 'card--flat' : '',
      animateHover ? 'card--animate-hover' : '',
    ]"
    @click="click"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    shadow: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    animateHover: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss">
.card {
  box-sizing: border-box;
  padding: 25px;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  position: relative;
  background-size: cover;
  width: 100%;
  transition: all 0.3s ease-out;
  overflow: hidden;
  background: #ffffff;

  &--flat {
    border: 1px solid #ffffff;
    box-shadow: none;

    &:hover {
    }
  }

  &--shadow {
    border: 1px solid #ffffff;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.02);

    &:hover {
    }
  }

  &--animate-hover {
    &:hover {
      border: 1px solid #ffffff;
      box-shadow: 0 4px 28px 0 rgba(0, 0, 0, 0.08);
      transform: translateY(-3px);
    }
  }
}
</style>
