var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "modal-fade", mode: "out-in" } }, [
    _vm.isVisible
      ? _c("div", { staticClass: "plans" }, [
          _c("div", { staticClass: "plans-overlay", on: { click: _vm.close } }),
          _c(
            "div",
            { staticClass: "plans-body" },
            [
              _c("span", { staticClass: "gg-close", on: { click: _vm.close } }),
              _c(
                "div",
                { staticClass: "plans-content" },
                [
                  _vm.title !== "Генеральный план" && _vm.title !== "План этажа"
                    ? _c(
                        "swiper",
                        {
                          ref: "swiper",
                          staticClass: "best-apartments-block__swiper",
                          attrs: {
                            options: {
                              slidesPerView: "auto",
                              centeredSlides: true,
                              allowSlidePrev: _vm.dlsPlans,
                              allowSlideNext: _vm.dlsPlans,
                              pagination: {
                                el: ".swiper-best-pagination",
                                clickable: true,
                              },
                            },
                          },
                        },
                        [
                          _c("swiper-slide", [
                            _c("div", { staticClass: "plans-content__slide" }, [
                              _c(
                                "div",
                                { staticClass: "plans-content__title" },
                                [
                                  _c("span", {
                                    domProps: { innerHTML: _vm._s(_vm.title) },
                                  }),
                                  _vm.dlsPlans
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "plans-content__title-sub",
                                        },
                                        [_vm._v(" Основная планировка")]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "plans-content__image" },
                                [
                                  _vm.title !== "План этажа" &&
                                  _vm.title !== "Генеральный план"
                                    ? _c("img", { attrs: { src: _vm.src } })
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._l(_vm.alternative, function (plan, index) {
                            return _c("swiper-slide", { key: index }, [
                              _c(
                                "div",
                                { staticClass: "plans-content__slide" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "plans-content__title" },
                                    [
                                      _vm._v(" " + _vm._s(_vm.title) + " "),
                                      _c("span", [
                                        _vm._v(
                                          "Возможная планировка " +
                                            _vm._s(index + 1)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "plans-content__image" },
                                    [
                                      _c("img", {
                                        attrs: { src: plan.image.image_mobile },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ])
                          }),
                          _vm.alternative && _vm.dlsPlans
                            ? _c("swiper-red-pagination", {
                                staticClass: "swiper-best-pagination",
                                attrs: { slot: "pagination" },
                                slot: "pagination",
                              })
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.title === "Генеральный план" || _vm.title === "План этажа"
                    ? _c(
                        "div",
                        {
                          ref: "slideDls",
                          staticClass: "plans-content__slide-dls",
                        },
                        [
                          _c("div", { staticClass: "plans-content__title" }, [
                            _c("span", {
                              domProps: { innerHTML: _vm._s(_vm.title) },
                            }),
                            _vm.dlsPlans
                              ? _c(
                                  "span",
                                  { staticClass: "plans-content__title-sub" },
                                  [_vm._v(" Основная планировка")]
                                )
                              : _vm._e(),
                          ]),
                          _vm.apartment.section &&
                          _vm.title === "Генеральный план"
                            ? _c("gen-plan", {
                                attrs: { section: _vm.apartment.section },
                              })
                            : _vm._e(),
                          _vm.apartment && _vm.title === "План этажа"
                            ? _c("floor-plan-zoom-poligon", {
                                staticClass: "plans-content__image-floor",
                                attrs: {
                                  src: _vm.apartment.floor_image.source,
                                  points: _vm.apartment.area_points,
                                  width: _vm.apartment.floor_image.width,
                                  height: _vm.apartment.floor_image.height,
                                  polygons: _vm.polygons,
                                },
                                on: {
                                  zoom: _vm.scrollToCenter,
                                  polygonClick: _vm.onPolygonClick,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "ui-button",
                {
                  staticClass: "apart-info__bottom-act1",
                  attrs: { id: "button-modal__plan" },
                  on: {
                    click: function ($event) {
                      return _vm.showApartmentDialog(
                        _vm.apartmentNumber,
                        "",
                        "apartment-plan__booking--callback"
                      )
                    },
                  },
                },
                [_vm._v(" Забронировать ")]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }