import "@/assets/js/lib/yaShare.js";
function yaShare(id, title, url, image) {
  console.log(document.querySelector('#' + id))
  new Ya.share2(id, {
    content: {
      title: title,
      url: url,
      image: image,
    },
    theme: {
      services: "vkontakte,telegram,whatsapp",
      lang: "ru",
      limit: 5,
      size: "m",
      bare: false,
    },
  });
}
export default yaShare;
