<template>
  <button class="ui-button-small" @click="click">
    <slot />
  </button>
</template>

<script>
export default {
  name: "UiButtonSmall",
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss">
.ui-button-small {
  &:hover {
    path,
    circle {
      stroke: #ffffff;
    }
  }
}
</style>

<style scoped lang="scss">
.ui-button-small {
  min-width: 28px;
  height: 28px;
  color: #e14313;
  border: 1px solid #ff5926;
  background-color: #ffffff;
  outline: none;
  cursor: pointer;
  transition: all 300ms ease-out;
  padding: 2px;
  display: inline-flex;
  box-shadow: 0px 5px 11px rgba(29, 29, 29, 0.2);
  align-items: center;
  justify-content: center;
  font-family: $font-family-bebas;

  &:disabled {
    background-color: #ffffff;
    border: 1px solid #e14313;
    color: #e14313;

    &:hover {
      color: #e14313;
      border: 1px solid #e14313;
    }
  }

  &:hover {
    background-color: #ff5926;
    border-color: #ff5926;
    color: #ffffff;
  }

  &:active {
    background-color: #c63206;
    border-color: #c63206;
    color: #ffffff;
  }
}
</style>
