<template>
  <div style="overflow: hidden">
    <div
      ref="points-wrap"
      class="floor-points"
      :style="{
        transform: `scale(${scale}) translate(${centerX}px, ${centerY}px)`,
      }"
      @click="handlerScaleMap"
    >
      <img
        :src="src"
        :width="width"
        :height="height"
        class="floor-points__image"
      />
      <div class="floor-points__hint">
        <svg
          :viewBox="`0 0 ${width} ${height}`"
          xmlns="http://www.w3.org/2000/svg"
        >
        <polygon
          v-for="(polygon, i) in polygons"
          :key="i"
          ref="points-polygons"
          :class="{ active: polygon.active }"
          :data-active="polygon.active || undefined"
          :points="polygon.points"
          @click="$emit('polygonClick', $event, polygon)"
        />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FloorPlanZoom",
  props: {
    src: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    polygons: {
      type: Array,
      default: () => []
    },
    zoom: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    centerY: 0,
    centerX: 0,
    scale: 1,
  }),
  methods: {
    getCenter() {
      const wrap = this.$refs["points-wrap"].getBoundingClientRect();
      const points = this.$refs["points-polygons"].find((el) => el.dataset.active).getBoundingClientRect();

      return {
        x: wrap.x - (points.x - wrap.width / 2 + points.width / 2),
        y: wrap.y - (points.y - wrap.height / 2 + points.height / 2),
      };
    },
    handlerScaleMap() {
      if (!this.zoom) {
        return;
      }
      if (this.scale === 1) {
        const center = this.getCenter();
        this.centerX = center.x;
        this.centerY = center.y;
        this.scale = 1.25;
        this.$emit("zoom");
      } else {
        this.scale = 1;
        this.centerX = 0;
        this.centerY = 0;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.floor-points {
  position: relative;
  transition: all 0.4s;
  &__hint {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    polygon {
      fill: #E1431333;
      cursor: pointer;
      transition: fill 0.3s;
      &:hover {
        fill: #E1431366;
      }
      &.active {
        fill: #E14313AC;
        pointer-events: none;
      }
    }
  }
  &__image {
    width: 100%;
    object-fit: contain;
    height: auto;
  }
}
</style>
