<template>
  <div class="street-compass-wrapper">
    <div class="street-compass-wrapper__street-north">{{ street.north }}</div>
    <div class="street-compass-wrapper__street-south">{{ street.south }}</div>
    <div class="street-compass-wrapper__street-west">{{ street.west }}</div>
    <div class="street-compass-wrapper__street-east">{{ street.east }}</div>
    <div
      class="street-compass-wrapper__compass"
      :style="{
        transform: 'rotate(' + compass + 'deg)',
      }"
    >
      <svg-compass />
    </div>
    <div class="street-compass-wrapper__slot">
      <slot />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import svgCompass from "@/assets/images/icons/compass";
export default {
  components: {
    svgCompass,
  },
  props: {
    korpus: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      street: {
        north: "",
        south: "",
        west: "",
        east: "",
      },
      compass: 0,
    };
  },
  computed: {
    ...mapGetters({
      streetCompass: "getStreetCompass",
    }),
  },
  mounted() {
    this.paymentStreetCompass();
  },
  methods: {
    paymentStreetCompass() {
      switch (this.korpus) {
        default:
          this.street.north = this.streetCompass.default.north;
          this.street.south = this.streetCompass.default.south;
          this.street.west = this.streetCompass.default.west;
          this.street.east = this.streetCompass.default.east;
          this.compass = this.streetCompass.default.compass;
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.street-compass-wrapper {
  position: relative;
  padding: 96px 26px 26px;
  font-size: 14px;
  color: #48484880;
  @media (min-width: $screen-lg) {
    font-size: 16px;
  }

  &__street {
    &-north {
      position: absolute;
      z-index: 10;
      top: 70px;
      left: 50%;
      transform: translate(-50%, 0);
    }
    &-south {
      position: absolute;
      z-index: 10;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
    &-west {
      position: absolute;
      z-index: 10;
      top: 50%;
      left: 0;
      transform: translate(0, calc(-50% + 35px)) rotate(180deg);
      writing-mode: vertical-rl;
    }
    &-east {
      position: absolute;
      z-index: 10;
      top: 50%;
      right: 0;
      transform: translate(0, calc(-50% + 35px));
      writing-mode: vertical-rl;
    }
  }
  &__compass {
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
