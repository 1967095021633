<template>
  <button class="ui-button-gray">
    <slot />
  </button>
</template>

<script>
export default {
  name: "UiButtonGray",
};
</script>

<style scoped lang="scss">
.ui-button-gray {
  display: flex;
  padding: 3px;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid #18181b55;
  background-color: transparent;
  outline: none;
  transition: all 300ms ease-out;

  &:hover {
    opacity: 0.5;
  }
}
</style>
