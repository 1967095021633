<template>
  <div class="check-tag"><icon-check class="check-tag__icon" /> <slot /></div>
</template>

<script>
import IconCheck from "@/components/icons/IconCheck";

export default {
  name: "CheckTag",
  components: {
    IconCheck,
  },
};
</script>

<style scoped lang="scss">
.check-tag {
  display: inline-flex;
  align-items: center;
  font-family: $font-family-rubik;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 132.5%;
  color: #231f20;

  @media screen and (min-width: $screen-lg) {
    font-size: 13px;
  }

  &__icon {
    margin-right: 8px;
  }
}
</style>
