var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "14",
        height: "14",
        viewBox: "0 0 14 14",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", {
        attrs: { opacity: "0.2", cx: "7", cy: "7", r: "7", fill: "#18181B" },
      }),
      _c("path", { attrs: { d: "M3.5 7L6.5 9.7L10.5 4", stroke: "#18181b" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }