<template>
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 1.37533L10.6164 6.35018L10.7118 6.64385H11.0206H16.2515L12.0196 9.71847L11.7698 9.89997L11.8652 10.1936L13.4817 15.1685L9.24981 12.0939L9 11.9124L8.75019 12.0939L4.51833 15.1685L6.13476 10.1936L6.23018 9.89997L5.98037 9.71847L1.74851 6.64385H6.97937H7.28815L7.38357 6.35018L9 1.37533Z"
      stroke="#18181B"
      stroke-width="0.85"
    />
  </svg>
</template>

<script>
export default {
  name: "IconStar",
};
</script>

<style scoped lang="scss"></style>
