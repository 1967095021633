var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "23",
        viewBox: "0 0 24 23",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M21.4 3C21.4 2.77909 21.2209 2.6 21 2.6L17.4 2.6C17.1791 2.6 17 2.77909 17 3C17 3.22091 17.1791 3.4 17.4 3.4L20.6 3.4L20.6 6.6C20.6 6.82091 20.7791 7 21 7C21.2209 7 21.4 6.82091 21.4 6.6L21.4 3ZM16.2828 8.28284L21.2828 3.28284L20.7172 2.71716L15.7172 7.71716L16.2828 8.28284Z",
          fill: "#E14313",
        },
      }),
      _c("path", {
        attrs: {
          d: "M21.4 20C21.4 20.2209 21.2209 20.4 21 20.4L17.4 20.4C17.1791 20.4 17 20.2209 17 20C17 19.7791 17.1791 19.6 17.4 19.6L20.6 19.6L20.6 16.4C20.6 16.1791 20.7791 16 21 16C21.2209 16 21.4 16.1791 21.4 16.4L21.4 20ZM16.2828 14.7172L21.2828 19.7172L20.7172 20.2828L15.7172 15.2828L16.2828 14.7172Z",
          fill: "#E14313",
        },
      }),
      _c("path", {
        attrs: {
          d: "M2.6 3C2.6 2.77909 2.77909 2.6 3 2.6L6.6 2.6C6.82091 2.6 7 2.77909 7 3C7 3.22091 6.82091 3.4 6.6 3.4L3.4 3.4L3.4 6.6C3.4 6.82091 3.22091 7 3 7C2.77909 7 2.6 6.82091 2.6 6.6L2.6 3ZM7.71716 8.28284L2.71716 3.28284L3.28284 2.71716L8.28284 7.71716L7.71716 8.28284Z",
          fill: "#E14313",
        },
      }),
      _c("path", {
        attrs: {
          d: "M2.6 20C2.6 20.2209 2.77909 20.4 3 20.4L6.6 20.4C6.82091 20.4 7 20.2209 7 20C7 19.7791 6.82091 19.6 6.6 19.6L3.4 19.6L3.4 16.4C3.4 16.1791 3.22091 16 3 16C2.77909 16 2.6 16.1791 2.6 16.4L2.6 20ZM7.71716 14.7172L2.71716 19.7172L3.28284 20.2828L8.28284 15.2828L7.71716 14.7172Z",
          fill: "#E14313",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }