var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "19",
        height: "19",
        viewBox: "0 0 19 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0)" } }, [
        _c("path", {
          attrs: {
            d: "M17.0208 14.25H14.6458C14.4273 14.25 14.25 14.0727 14.25 13.8542C14.25 13.6357 14.4273 13.4583 14.6458 13.4583H17.0208C17.6755 13.4583 18.2083 12.9255 18.2083 12.2708V6.72917C18.2083 6.07446 17.6755 5.54167 17.0208 5.54167H1.97917C1.32446 5.54167 0.791667 6.07446 0.791667 6.72917V12.2708C0.791667 12.9255 1.32446 13.4583 1.97917 13.4583H4.35417C4.57267 13.4583 4.75 13.6357 4.75 13.8542C4.75 14.0727 4.57267 14.25 4.35417 14.25H1.97917C0.887458 14.25 0 13.3618 0 12.2708V6.72917C0 5.63825 0.887458 4.75 1.97917 4.75H17.0208C18.1125 4.75 19 5.63825 19 6.72917V12.2708C19 13.3618 18.1125 14.25 17.0208 14.25Z",
            fill: "#18181B",
          },
        }),
        _c("path", {
          attrs: {
            d: "M11.4786 16.6247H6.7286C6.5101 16.6247 6.33276 16.4473 6.33276 16.2288C6.33276 16.0103 6.5101 15.833 6.7286 15.833H11.4786C11.6971 15.833 11.8744 16.0103 11.8744 16.2288C11.8744 16.4473 11.6971 16.6247 11.4786 16.6247Z",
            fill: "#18181B",
          },
        }),
        _c("path", {
          attrs: {
            d: "M11.4786 15.0417H6.7286C6.5101 15.0417 6.33276 14.8643 6.33276 14.6458C6.33276 14.4273 6.5101 14.25 6.7286 14.25H11.4786C11.6971 14.25 11.8744 14.4273 11.8744 14.6458C11.8744 14.8643 11.6971 15.0417 11.4786 15.0417Z",
            fill: "#18181B",
          },
        }),
        _c("path", {
          attrs: {
            d: "M8.31193 13.4587H6.7286C6.5101 13.4587 6.33276 13.2813 6.33276 13.0628C6.33276 12.8443 6.5101 12.667 6.7286 12.667H8.31193C8.53043 12.667 8.70776 12.8443 8.70776 13.0628C8.70776 13.2813 8.53043 13.4587 8.31193 13.4587Z",
            fill: "#18181B",
          },
        }),
        _c("path", {
          attrs: {
            d: "M14.6453 5.54167C14.4268 5.54167 14.2494 5.36433 14.2494 5.14583V1.97917C14.2494 1.32446 13.7166 0.791667 13.0619 0.791667H5.93693C5.28222 0.791667 4.74943 1.32446 4.74943 1.97917V5.14583C4.74943 5.36433 4.5721 5.54167 4.3536 5.54167C4.1351 5.54167 3.95776 5.36433 3.95776 5.14583V1.97917C3.95776 0.88825 4.84522 0 5.93693 0H13.0619C14.1536 0 15.0411 0.88825 15.0411 1.97917V5.14583C15.0411 5.36433 14.8638 5.54167 14.6453 5.54167Z",
            fill: "#18181B",
          },
        }),
        _c("path", {
          attrs: {
            d: "M13.0619 19.0003H5.93693C4.84522 19.0003 3.95776 18.1121 3.95776 17.0212V10.6878C3.95776 10.4693 4.1351 10.292 4.3536 10.292H14.6453C14.8638 10.292 15.0411 10.4693 15.0411 10.6878V17.0212C15.0411 18.1121 14.1536 19.0003 13.0619 19.0003ZM4.74943 11.0837V17.0212C4.74943 17.6759 5.28222 18.2087 5.93693 18.2087H13.0619C13.7166 18.2087 14.2494 17.6759 14.2494 17.0212V11.0837H4.74943Z",
            fill: "#18181B",
          },
        }),
      ]),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0" } }, [
          _c("rect", { attrs: { width: "19", height: "19", fill: "white" } }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }