<template>
  <transition name="modal-fade" mode="out-in">
    <div v-if="isVisible" class="plans">
      <div class="plans-overlay" @click="close" />
      <div class="plans-body">
        <span class="gg-close" @click="close" />
        <div class="plans-content">
          <swiper
            v-if="title !== 'Генеральный план' && title !== 'План этажа'"
            ref="swiper"
            class="best-apartments-block__swiper"
            :options="{
              slidesPerView: 'auto',
              centeredSlides: true,
              allowSlidePrev: dlsPlans,
              allowSlideNext: dlsPlans,
              pagination: {
                el: '.swiper-best-pagination',
                clickable: true,
              },
            }"
          >
            <swiper-slide>
              <div class="plans-content__slide">
                <div class="plans-content__title">
                  <span v-html="title" />
                  <span v-if="dlsPlans" class="plans-content__title-sub">
                    Основная планировка</span
                  >
                </div>
                <div class="plans-content__image">
                  <img
                    v-if="
                      title !== 'План этажа' && title !== 'Генеральный план'
                    "
                    class=""
                    :src="src"
                  />
                </div>
              </div>
            </swiper-slide>
            <swiper-slide v-for="(plan, index) in alternative" :key="index">
              <div class="plans-content__slide">
                <div class="plans-content__title">
                  {{ title }} <span>Возможная планировка {{ index + 1 }}</span>
                </div>
                <div class="plans-content__image">
                  <img class="" :src="plan.image.image_mobile" />
                </div>
              </div>
            </swiper-slide>
            <swiper-red-pagination
              v-if="alternative && dlsPlans"
              slot="pagination"
              class="swiper-best-pagination"
            />
          </swiper>
          <div
            v-if="title === 'Генеральный план' || title === 'План этажа'"
            ref="slideDls"
            class="plans-content__slide-dls"
          >
            <div class="plans-content__title">
              <span v-html="title" />
              <span v-if="dlsPlans" class="plans-content__title-sub">
                Основная планировка</span
              >
            </div>
            <gen-plan
              v-if="apartment.section && title === 'Генеральный план'"
              :section="apartment.section"
            />
            <floor-plan-zoom-poligon
              v-if="apartment && title === 'План этажа'"
              :src="apartment.floor_image.source"
              :points="apartment.area_points"
              :width="apartment.floor_image.width"
              :height="apartment.floor_image.height"
              :polygons="polygons"
              class="plans-content__image-floor"
              @zoom="scrollToCenter"
              @polygonClick="onPolygonClick"
            />
          </div>
        </div>
        <ui-button
          id="button-modal__plan"
          class="apart-info__bottom-act1"
          @click="
            showApartmentDialog(
              apartmentNumber,
              '',
              'apartment-plan__booking--callback'
            )
          "
        >
          Забронировать
        </ui-button>
      </div>
    </div>
  </transition>
</template>

<script>
import UiButton from "@/components/ui/UIButton";
import SwiperRedPagination from "@/components/elements/SwiperRedPagination";
import FloorPlanZoomPoligon from "@/components/elements/FloorPlanZoomPoligon";
import genPlan from "@/components/elements/GenPlan";

export default {
  name: "ModalPlansApartmens",
  components: {
    UiButton,
    SwiperRedPagination,
    FloorPlanZoomPoligon,
    genPlan,
  },
  props: {
    src: String,
    title: String,
    apartment: {
      type: Object,
      default: () => {},
    },
    alternative: null,
    apartmentNumber: {
      type: String,
      default: "",
    },
    visible: {
      type: Boolean,
      default: false,
    },
    layoutSelected: {
      type: Number,
      default: 0,
    },
    dlsPlans: {
      type: Boolean,
      default: false,
    },
    polygons: {
      type: Array,
      default: undefined
    }
  },
  data: () => ({
    isVisible: false,
  }),
  watch: {
    visible() {
      this.isVisible = this.visible;
      setTimeout(() => {}, 0);
    },
    isVisible(newVal) {
      if (newVal) {
        document.body.style = "overflow: hidden;";
      } else {
        document.body.style = "";
      }
    },
  },
  created() {
    this.isVisible = this.visible;
  },
  deactivated() {
    this.close();
  },
  methods: {
    scrollToCenter() {
      this.$refs.slideDls.scrollTo({
        top: this.$refs.slideDls.offsetHeight / 1.5,
        behavior: "smooth",
      });
    },
    close() {
      this.isVisible = false;
      this.$emit("close");
    },
    onPolygonClick(e, polygon) {
      this.$emit('polygonClick', e, polygon);
      this.close();
    }
  },
};
</script>

<style lang="scss" scoped>
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}
.modal-fade-enter {
  opacity: 0;
}

.modal-fade-leave-to {
  opacity: 0;
}
.plans {
  position: fixed;
  z-index: 9999;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &-overlay {
    position: absolute;
    z-index: 1000;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(36, 36, 36, 0.8);
  }

  &-body {
    background: #fff;
    z-index: 1001;
    width: 95%;
    text-align: center;
    position: relative;
    padding: 27px 0;

    .gg-close {
      z-index: 10;
      box-sizing: border-box;
      position: absolute;
      top: 10px;
      right: 10px;
      display: block;
      transform: scale(var(--ggs, 1));
      width: 22px;
      height: 22px;
      border: 2px solid transparent;
      border-radius: 40px;
      cursor: pointer;

      @media screen and (min-width: $screen-sm) {
        top: 45px;
        right: 45px;
      }
    }
    .gg-close::after,
    .gg-close::before {
      content: "";
      display: block;
      box-sizing: border-box;
      position: absolute;
      width: 16px;
      height: 2px;
      background: #8c8c8d;
      transform: rotate(45deg);
      border-radius: 5px;
      top: 8px;
      left: 1px;

      @media screen and (min-width: 2000px) {
        width: 32px;
      }
    }
    .gg-close::after {
      transform: rotate(-45deg);
    }
  }
  &-content {
    &__slide {
      height: 80vh;
      overflow-y: auto;
      &-dls {
        height: 80vh;
        overflow-y: auto;
        margin: auto;
      }
    }
    &__image {
      height: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      &-floor {
        margin: auto;
        max-width: 1000px;
        overflow-y: scroll;
        &_vertical {
          width: 32vh;
        }
      }
      img {
        object-fit: contain;
        max-height: 85%;
        max-width: 100%;
      }
    }

    &__title {
      font-family: Bebas Neue;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      letter-spacing: 0.05em;
      margin-top: 5px;

      @media screen and (min-width: $screen-sm) {
        font-size: 48px;
      }

      &-sub {
        display: block;
        font-size: 12px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #868687;

        @media screen and (min-width: $screen-md) {
          display: inline;
        }

        @media screen and (min-width: $screen-sm) {
          font-size: 24px;
        }
      }
    }

    .swiper-container {
      position: relative;
      height: 100%;
      width: 75%;
    }

    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .swiper-best-pagination {
      position: absolute;
      bottom: 10px;
    }
  }
}
</style>
