var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gen-plan" }, [
    _c("picture", [
      _c("source", {
        attrs: {
          srcset: require("@/assets/images/apartment/plan.png"),
          media: "(min-width: 768px)",
        },
      }),
      _c("img", {
        staticClass: "header__img",
        attrs: { src: require("@/assets/images/apartment/plan-m.png") },
      }),
    ]),
    _c(
      "svg",
      {
        staticClass: "gen-plan__svg",
        class: _vm.switchSection(_vm.section),
        attrs: {
          width: "3750",
          height: "3750",
          viewBox: "0 0 3750 3750",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      [
        _c("path", {
          attrs: {
            d: "M737 811L329 796L323 964H332V980H340V995H349V1011H357L354 1108H362V1124H371V1140H380V1156H390L386 1248H395V1264H404V1279H412V1295H421L417 1392H426V1408H434V1424H443V1440H452L448 1534H457V1549H465V1565H474V1581H483L479 1676H488V1692H496V1708H505V1724H512L509 1819H518V1835H527L526 1850H535V1866H544L540 1961H549V1977H558V1993H567V2009H573L571 2104L1154 3182H1557L1403.5 2891.5L1409 2798H1407V2794H1405V2789H1403V2785H1401V2780H1399V2776H1396V2771H1394V2767H1392V2762H1390V2758H1388V2753H1386V2748H1384V2744H1382V2739H1380V2735H1378V2730H1376L1369 2573L1447 2571V2559H1440L1442 2466H1433V2450H1425V2434H1416V2419H1412L1415 2321H1406V2305H1397V2289H1389V2273H1380V2181L1372 2179V2165L1363 2163V2149L1354 2147V2132H1350L1353 2033H1344V2018H1336V2002H1327V1986H1318L1321 1878H1313V1863H1304V1847H1295V1831H1287V1815H1278V1799H1269V1783H1260V1767H1252V1751H1243V1735H1234V1719H1225V1703H1217V1687H1208V1671H1199V1655H1191V1639H1182V1623H1173V1607H1164V1592H1156V1576H1147V1560H1138V1544H1129V1528H1121V1512H1112V1496H1103V1480H1095V1464H1086V1448H1077V1432H1068V1416H1060V1400H1051V1384H1042V1368H1033V1352H1025V1337H1016V1321H1007V1305H999V1289H990V1273H981V1257H973V1241H964V1225H955V1209H946V1193H938V1177H929V1161H920V1145H911V1129H903V1113H894V1097H885V1081H877V1065H868V1050H859V1034H851V1018H842V1002H833V986H824V970H816V954H807V938H798V922H789V906H781V890H772V874H763V858H754V842H746V826H737V811Z",
            fill: "#E14313",
            "fill-opacity": "0.7",
          },
        }),
        _c("path", {
          attrs: {
            d: "M1622 2567L1369 2573L1376 2730H1378V2735H1380V2739H1382V2744H1384V2748H1386V2753H1388V2758H1390V2762H1392V2767H1394V2771H1396V2776H1399V2780H1401V2785H1403V2789H1405V2794H1407V2798H1409L1403.5 2891.5L1557 3182L2055 3165L2053 3102L2278 3092L2219 2791L2211 2552L1835 2562V2692H1761.5L1728.5 2750L1696 2692H1622V2567Z",
            fill: "#A73131",
            "fill-opacity": "0.5",
          },
        }),
        _c("path", {
          attrs: {
            d: "M2609 2542L2211 2552L2219 2791L2278 3092L2526 3081L2535 3150L3168.5 3126L3175.5 2830L3163 2528.5L2822 2536.5V2662H2748L2715 2719L2682 2662H2609V2542Z",
            fill: "#22CA79",
            "fill-opacity": "0.5",
          },
        }),
        _c("path", {
          attrs: {
            d: "M3163 2528.5L2822 2536.5V2449H2733V2415L2770 1912L2872 1910V2054H2946L2979 2112L3011 2054H3085V1906L3182 1904L3203 2400L3175.5 2828L3163 2528.5Z",
            fill: "#000AFF",
            "fill-opacity": "0.5",
          },
        }),
        _c("path", {
          attrs: {
            d: "M2640 615L2416 623L2421 859L2391 861V1564L2396 1674V1800H2402V1803L3206 1798V1623L3226 1622L3235 1446V840L3226 661L2932 670L2929 637L2643 648L2640 615Z",
            fill: "#00FF38",
            "fill-opacity": "0.5",
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }