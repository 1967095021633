<template>
  <div class="">
    <card-apartment
      :class="{
        'apartment-plan-card': true,
        'apartment-plan-card--active': selected,
      }"
      :shadow="selected"
      :name="planType"
      :image="image"
      :image-jpg="imageJpg"
      no-header
      @click="$emit('click')"
    />
    <div class="subtitle">
      {{ subtitle }}
    </div>
  </div>
</template>

<script>
import CardApartment from "./CardApartment";
export default {
  name: "CardApartmentPlan",
  components: { CardApartment },
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    planType: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    imageJpg: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">
.apartment-plan-card {
  padding: 5px;
  text-align: center;
  background: transparent;
  border: 2px solid transparent;

  &:hover {
    border: 2px solid transparent;
    background: #ffffff;
  }

  &--active {
    // border: 2px solid #fd5620;
    background: #ffffff;
    transform: translateY(-3px);
    box-shadow: 6px 8px 30px rgba(0, 0, 0, 0.17);
  }
}
.subtitle {
  display: none;
  font-size: 12px;
  text-align: center;
  margin-top: 7px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  color: #868687;

  @media screen and (min-width: $screen-sm) {
    font-size: 14px;
    display: block;
  }
}
</style>
