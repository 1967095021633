var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("card-apartment", {
        class: {
          "apartment-plan-card": true,
          "apartment-plan-card--active": _vm.selected,
        },
        attrs: {
          shadow: _vm.selected,
          name: _vm.planType,
          image: _vm.image,
          "image-jpg": _vm.imageJpg,
          "no-header": "",
        },
        on: {
          click: function ($event) {
            return _vm.$emit("click")
          },
        },
      }),
      _c("div", { staticClass: "subtitle" }, [
        _vm._v(" " + _vm._s(_vm.subtitle) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }