var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "street-compass-wrapper" }, [
    _c("div", { staticClass: "street-compass-wrapper__street-north" }, [
      _vm._v(_vm._s(_vm.street.north)),
    ]),
    _c("div", { staticClass: "street-compass-wrapper__street-south" }, [
      _vm._v(_vm._s(_vm.street.south)),
    ]),
    _c("div", { staticClass: "street-compass-wrapper__street-west" }, [
      _vm._v(_vm._s(_vm.street.west)),
    ]),
    _c("div", { staticClass: "street-compass-wrapper__street-east" }, [
      _vm._v(_vm._s(_vm.street.east)),
    ]),
    _c(
      "div",
      {
        staticClass: "street-compass-wrapper__compass",
        style: {
          transform: "rotate(" + _vm.compass + "deg)",
        },
      },
      [_c("svg-compass")],
      1
    ),
    _c(
      "div",
      { staticClass: "street-compass-wrapper__slot" },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }