var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    { staticClass: "ui-button-small", on: { click: _vm.click } },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }