<template>
  <card
    animate-hover
    :class="{ 'card-apartment': true }"
    :shadow="shadow"
    :flat="flat"
    @click="click"
  >
    <div class="card-apartment__plan">
      <picture>
        <source v-if="imageJpg" :srcset="imageJpg" type="image/png" />
        <source :srcset="image" type="image/webp" />
        <img :src="image" loading="lazy" />
      </picture>
    </div>
  </card>
</template>

<script>
import Card from "./Card";

export default {
  name: "CardApartment",
  components: {
    Card,
  },
  props: {
    // noHeader: {
    //   type: Boolean,
    //   default: false
    // },
    // hot: {
    //   type: Boolean,
    //   default: false
    // },
    // id: {
    //   type: String,
    //   default: ''
    // },
    image: {
      type: String,
      required: true,
    },
    imageJpg: {
      type: String,
      required: false,
    },
    // name: {
    //   type: String
    // },
    // planType: {
    //   type: String,
    //   required: false
    // }
    // area: {
    //   required: false
    // },
    // korpus: {
    //   required: false
    // },
    // section: {
    //   required: false
    // },
    // rooms: {
    //   required: false
    // },
    // floor: {
    //   required: false
    // },
    shadow: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    // favorite: {
    //   type: Boolean,
    //   default: false
    // },
    // alternative: {
    //   type: Boolean,
    //   default: false
    // }
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped lang="scss">
.card-apartment {
  cursor: pointer;
  background: #ffffff;
  position: relative;

  &__header {
    position: relative;
    margin-bottom: 5px;
    height: 17px;
  }

  &__plan {
    img {
      width: 100%;
    }
  }

  &__hot {
    position: absolute;
  }

  &__favorite {
    position: absolute;
    right: 0;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__props {
    font-size: 13px;
    font-weight: 300;
    color: #696969;
    margin-bottom: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__number {
    font-size: 12px;
    color: #696969;
  }

  &__name {
    font-size: 14px;
    font-weight: 300;
    color: #696969;
    // margin-bottom: 5px;
    margin-top: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media only screen and (max-width: $screen-sm) {
      font-size: 17px;
    }
  }

  &__type {
    color: #a2a2a2;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__cost-from {
    color: #ff4313;
    font-size: 15px;
    font-weight: 300;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__cost-sale {
    color: #505050;
    text-decoration: line-through;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__cost {
    margin-top: 15px;
    color: #ff4313;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    span {
      font-size: 20px;
      font-weight: 500;

      @media only screen and (max-width: $screen-sm) {
        font-size: 18px;
      }
    }
  }
}
</style>
