var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { overflow: "hidden" } }, [
    _c(
      "div",
      {
        ref: "points-wrap",
        staticClass: "floor-points",
        style: {
          transform: `scale(${_vm.scale}) translate(${_vm.centerX}px, ${_vm.centerY}px)`,
        },
        on: { click: _vm.handlerScaleMap },
      },
      [
        _c("img", {
          staticClass: "floor-points__image",
          attrs: { src: _vm.src, width: _vm.width, height: _vm.height },
        }),
        _c("div", { staticClass: "floor-points__hint" }, [
          _c(
            "svg",
            {
              attrs: {
                viewBox: `0 0 ${_vm.width} ${_vm.height}`,
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            _vm._l(_vm.polygons, function (polygon, i) {
              return _c("polygon", {
                key: i,
                ref: "points-polygons",
                refInFor: true,
                class: { active: polygon.active },
                attrs: {
                  "data-active": polygon.active || undefined,
                  points: polygon.points,
                },
                on: {
                  click: function ($event) {
                    return _vm.$emit("polygonClick", $event, polygon)
                  },
                },
              })
            }),
            0
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }