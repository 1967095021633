var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "15",
        height: "15",
        viewBox: "0 0 15 15",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M14.9949 7.49599L8.15884 0V4.4902H7.36473C5.39761 4.4902 3.54813 5.25627 2.1571 6.6473C0.766068 8.03833 0 9.88781 0 11.855V15L1.31195 13.5625C3.0669 11.6398 5.55805 10.5275 8.15884 10.5022V14.9921L14.9949 7.49599ZM0.879021 12.7402V11.855C0.879021 10.1226 1.55365 8.4938 2.77863 7.26883C4.0036 6.04385 5.63232 5.36922 7.36473 5.36922H9.03774V2.26833L13.8054 7.49599L9.03774 12.7238V9.62288H8.24913C5.47462 9.62288 2.80655 10.7553 0.879021 12.7402Z",
          fill: "#231F20",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }