<template>
  <svg width="45" height="50" viewBox="0 0 45 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.2" filter="url(#filter0_d_3_3304)">
    <rect width="23" height="28" transform="matrix(-1 0 0 1 34 6)" fill="#E63E1F"/>
    </g>
    <rect x="-0.5" y="0.5" width="27" height="27" transform="matrix(-1 0 0 1 36 6)" fill="#F4F4F4" stroke="#E14313"/>
    <path
      v-if="left"
      d="M15.7172 20.2828C15.5609 20.1266 15.5609 19.8734 15.7172 19.7172L18.2627 17.1716C18.419 17.0154 18.6722 17.0154 18.8284 17.1716C18.9846 17.3278 18.9846 17.581 18.8284 17.7373L16.5657 20L18.8284 22.2627C18.9846 22.419 18.9846 22.6722 18.8284 22.8284C18.6722 22.9846 18.419 22.9846 18.2627 22.8284L15.7172 20.2828ZM31 20.4L16 20.4L16 19.6L31 19.6L31 20.4Z"
      fill="#E14313"
    />
    <path
      v-if="right"
      d="M29.2828 20.2828C29.4391 20.1266 29.4391 19.8734 29.2828 19.7172L26.7373 17.1716C26.581 17.0154 26.3278 17.0154 26.1716 17.1716C26.0154 17.3278 26.0154 17.581 26.1716 17.7373L28.4343 20L26.1716 22.2627C26.0154 22.419 26.0154 22.6722 26.1716 22.8284C26.3278 22.9846 26.581 22.9846 26.7373 22.8284L29.2828 20.2828ZM14 20.4L29 20.4L29 19.6L14 19.6L14 20.4Z"
      fill="#E14313"
    />
    <defs>
    <filter id="filter0_d_3_3304" x="0" y="0" width="45" height="50" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="5"/>
    <feGaussianBlur stdDeviation="5.5"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.1125 0 0 0 0 0.1125 0 0 0 0 0.1125 0 0 0 0.59 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_3304"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_3304" result="shape"/>
    </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "SliderArrow",
  props: {
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
  }
};
</script>

<style scoped lang="scss">
svg {
  cursor: pointer;

  path, rect {
    transition: all 0.5s;
  }

  &:hover {
    @media (hover: hover) {
      rect {
        fill: #E14313;
        transition: all 0.5s;
      }
      path {
        filL: #FFFFFF;
        transition: all 0.5s;
      }
    }
  }
}
</style>
