<template>
  <div class="apgallery">
    <div class="apgallery__top">
      <swiper 
        ref="swiper" 
        :options="{
          init: false,
          loop: false,
          notNextTick: true,
          setWrapperSize :true,
          autoHeight: true,
          observer: true,
          observeParents: true,
          spaceBetween: 30
        }" 
        @slideChangeTransitionEnd="transitionHandler"
      >
        <swiper-slide 
          v-for="(slide, i) in slides" 
          :key="i" 
          :class="{
            'apgallery__slide': 1,
            'apgallery__slide--video': slide.video,
          }"
        >
          <image-sources v-if="!slide.video" :sources="slide.images" cover />
          <video ref="video" v-else :src="slide.video" playsinline muted loop />
        </swiper-slide>
      </swiper>
      <div v-if="showNav && slides.length > 1" class="apgallery__navigation navigation">
        <slider-arrow
          class="navigation__prev"
          aria-label="Предыдущий слайд"
          left
          @click.native="onPrevClick"
        />
        <slider-arrow
          class="navigation__next"
          aria-label="Следующий слайд"
          right
          @click.native="onNextClick"
        />
      </div>
    </div>
    <template v-if="slides.length > 1">
      <div class="apgallery__cnt">
        <div class="apgallery__icon"></div>
        <div class="apgallery__title" v-html="galleryTitle"></div>
      </div>
      <div 
        class="apgallery__thumbs" 
        :style="slides.length < 4
            ? {
                gridTemplateColumns: 'repeat(' + slides.length + ', 1fr)',
              }
            : null
        ">
        <div 
          v-for="(slide, i) in slides" 
          :key="i" class="apgallery__thumbs-slide" 
          :class="{ 'apgallery__thumbs-slide--hovered': index == i }"
          @click="handleClick(i)"
        >
          <div class="apgallery__thumbs-image">
            <img :src="slide.thumb" alt="" />
          </div>
          <div class="apgallery__thumbs-title">
            {{ slide.title }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
  
<script>

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { ImageSources } from "@osnova-adv/ms3-blocks";
import SliderArrow from "@/components/icons/SliderArrow.vue";

export default {
  name: "ApartmentGallery",
  components: {
    Swiper,
    SwiperSlide,
    ImageSources,
    SliderArrow,
  },
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
    showed: {
      type: Boolean,
      default: false,
    },
    showNav: {
      type: Boolean,
      default: false,
    },
    galleryTitle: {
      type: String,
      default: "Другие комнаты",
    },
  },
  data() {
    return {
      index: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.swiper.$swiper.init();
    });
    this.runVideo();
  },
  watch: {
    index() {
      this.runVideo();
    },
  },
  methods: {
    handleClick(i) {
      this.$refs.swiper.$swiper.slideTo(i);
      this.index = this.$refs.swiper.$swiper.realIndex;
    },
    transitionHandler() {
      this.index = this.$refs.swiper.$swiper.realIndex;
    },
    runVideo() {
      if (!this.$refs.video?.length) {
        return;
      }
      for (let j = 0; j < this.$refs.video.length; j++) {
        this.$refs.video[j].pause();
        this.$refs.video[j].currentTime = 0;
      }
      setTimeout(() => {
        this.$refs.video[this.index].play();
      }, 500);
    },
    onPrevClick() {
      this.$refs.swiper?.$swiper.slidePrev();
    },
    onNextClick() {
      this.$refs.swiper?.$swiper.slideNext();
    },
  },
};
</script>

<style lang="scss">
.apgallery {
  display: flex;
  align-items: center;
  flex-direction: column;

  .swiper-wrapper {
    align-items: center;
  }

  &__cnt {
    width: 100%;
    max-width: 460px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    letter-spacing: 0.05em;
    @media screen and (max-width: 767px) {
      max-width: 276px;
    }
  }

  &__icon {
    background: url(~@/assets/images/icons/gallery_icon.svg);
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }
  &__top {
    position: relative;
    margin-top: 30px;
    margin-bottom: 40px;
    width: 100%;

    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }

    @media screen and (max-width: 1199px) {
      margin-top: 40px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 20px;
    }

    img {
      max-width: 100%;
    }
  }

  &__navigation, .navigation {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: space-between;
    align-items:center;

    &__prev,
    &__next {
      z-index: 2;
      margin: 19px;

      @media screen and (max-width: $screen-sm) {
        margin: 8px;
        width: 32px;
        height: 32px;
      }
    }
  }

  &__slide {
    width: 100%;

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    &--video {
      width: 100% !important;
      cursor: pointer;
    }

    video {
      width: 100%;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 5%;
    text-align: center;
    color: #484848;
  }

  &__thumbs {
    padding-top: 12px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 12px 20px;

    @media screen and (max-width: 767px) {
      grid-gap: 10px 12px;
    }
  }

  &__thumbs-slide {
    cursor: pointer;

    &:hover,
    &--hovered {
      .apgallery__thumbs-image {
        border-color: var(--ms-new-apart-gallery-border, #8fa6cb);
      }
    }
  }

  &__thumbs-image {
    width: 100px;
    height: 100px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 3px;
    background: #ffffff;
    margin-bottom: 8px;
    border: 1px solid transparent;

    @media screen and (max-width: 767px) {
      width: 60px;
      height: 60px;
      padding: 6px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__thumbs-title {
    font-style: normal;
    font-weight: 350;
    font-size: 14px;
    line-height: 160%;
    text-align: center;
    color: #484848;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  &__thumbs-arrow {
    background: #ffffff;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    width: 30px;
    align-items: center;
    cursor: pointer;

    &--prev {
      border-radius: 3px 0px 0px 3px;
    }

    &--next {
      border-radius: 3px 0px 0px 3px;
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }
  }
}
</style>
  