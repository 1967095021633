var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "apgallery" },
    [
      _c(
        "div",
        { staticClass: "apgallery__top" },
        [
          _c(
            "swiper",
            {
              ref: "swiper",
              attrs: {
                options: {
                  init: false,
                  loop: false,
                  notNextTick: true,
                  setWrapperSize: true,
                  autoHeight: true,
                  observer: true,
                  observeParents: true,
                  spaceBetween: 30,
                },
              },
              on: { slideChangeTransitionEnd: _vm.transitionHandler },
            },
            _vm._l(_vm.slides, function (slide, i) {
              return _c(
                "swiper-slide",
                {
                  key: i,
                  class: {
                    apgallery__slide: 1,
                    "apgallery__slide--video": slide.video,
                  },
                },
                [
                  !slide.video
                    ? _c("image-sources", {
                        attrs: { sources: slide.images, cover: "" },
                      })
                    : _c("video", {
                        ref: "video",
                        refInFor: true,
                        attrs: {
                          src: slide.video,
                          playsinline: "",
                          muted: "",
                          loop: "",
                        },
                        domProps: { muted: true },
                      }),
                ],
                1
              )
            }),
            1
          ),
          _vm.showNav && _vm.slides.length > 1
            ? _c(
                "div",
                { staticClass: "apgallery__navigation navigation" },
                [
                  _c("slider-arrow", {
                    staticClass: "navigation__prev",
                    attrs: { "aria-label": "Предыдущий слайд", left: "" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onPrevClick.apply(null, arguments)
                      },
                    },
                  }),
                  _c("slider-arrow", {
                    staticClass: "navigation__next",
                    attrs: { "aria-label": "Следующий слайд", right: "" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onNextClick.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.slides.length > 1
        ? [
            _c("div", { staticClass: "apgallery__cnt" }, [
              _c("div", { staticClass: "apgallery__icon" }),
              _c("div", {
                staticClass: "apgallery__title",
                domProps: { innerHTML: _vm._s(_vm.galleryTitle) },
              }),
            ]),
            _c(
              "div",
              {
                staticClass: "apgallery__thumbs",
                style:
                  _vm.slides.length < 4
                    ? {
                        gridTemplateColumns:
                          "repeat(" + _vm.slides.length + ", 1fr)",
                      }
                    : null,
              },
              _vm._l(_vm.slides, function (slide, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass: "apgallery__thumbs-slide",
                    class: {
                      "apgallery__thumbs-slide--hovered": _vm.index == i,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleClick(i)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "apgallery__thumbs-image" }, [
                      _c("img", { attrs: { src: slide.thumb, alt: "" } }),
                    ]),
                    _c("div", { staticClass: "apgallery__thumbs-title" }, [
                      _vm._v(" " + _vm._s(slide.title) + " "),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }