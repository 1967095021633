var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "card",
    {
      class: { "card-apartment": true },
      attrs: { "animate-hover": "", shadow: _vm.shadow, flat: _vm.flat },
      on: { click: _vm.click },
    },
    [
      _c("div", { staticClass: "card-apartment__plan" }, [
        _c("picture", [
          _vm.imageJpg
            ? _c("source", {
                attrs: { srcset: _vm.imageJpg, type: "image/png" },
              })
            : _vm._e(),
          _c("source", { attrs: { srcset: _vm.image, type: "image/webp" } }),
          _c("img", { attrs: { src: _vm.image, loading: "lazy" } }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }